import { useLanguage } from '../../contexts/LanguageContext';

function Vision() {

    const { language } = useLanguage();

    const translations = {
        fr: {
            title: "Ma Vision",
            ux: {title: "Expérience Utilisateur", text: "Dans ma philosophie du développement web, l'Expérience Utilisateur (UX) occupe une place fondamentale. Comprendre en profondeur notre audience permet de personnaliser chaque aspect du site, de l'architecture à l'esthétique, afin d'optimiser l'utilisation pour le public cible. Pour moi, l'UX n'est pas simplement un objectif à atteindre, mais plutôt une constante à maintenir tout au long du processus de développement, on doit toujours garder à l’esprit à qui s’adresse notre travail. En résumé, l'UX est selon moi la boussole qui doit guider chaque décision, garantissant ainsi une expérience utilisateur exceptionnelle."},
            accessibility: {title: "Accessibilité", text: "Au cœur de ma démarche de développement web réside une conviction profonde en faveur de l'accessibilité. L'inclusion est pour moi une priorité, et cela se reflète dans chaque aspect de mes projets. Le web doit être un espace égalitaire, où chacun peut participer pleinement, naviguer et interagir de manière fluide. En tant que créateurs du web de demain, il nous incombe la responsabilité de le rendre aussi inclusif et universel que possible. C'est dans cette optique que je m'engage à concevoir des expériences numériques qui embrassent la diversité et garantissent un accès équitable pour tous."},
            clean: {title: "Clean Code", text: "Je suis convaincu que la lisibilité, la simplicité, la testabilité et la réutilisabilité sont les piliers d'un projet web robuste.  La clarté du code n'est pas simplement une question esthétique, mais un impératif fonctionnel. Un code propre facilite la collaboration, le débogage et la maintenance à long terme. Je m'efforce continuellement de m'améliorer dans cette discipline, car je crois que la qualité du code transcende la simple exécution de tâches techniques, contribuant à la création de solutions technologiques durables et performantes."},
            learning: {title: "Soif d’Apprendre", text: "Ma soif d'apprentissage est l'un des moteurs majeurs qui m'ont orienté vers le développement web. Dans cet univers dynamique et en constante évolution, chaque jour est une nouvelle occasion d'explorer des technologies émergentes, d'affiner mes compétences et de relever des défis stimulants. En tant que développeuse issue d'une reconversion, je suis intimement consciente des défis inhérents à ce parcours. C'est pourquoi, depuis près d'un an, je me suis engagée dans le mentorat auprès de plusieurs individus en reconversion. J'apprécie grandement partager mes connaissances, vulgariser les concepts techniques et guider mes apprentis à travers leur parcours d'apprentissage. La pédagogie est pour moi une composante essentielle de cette démarche, et suivre les progrès de mes mentorés est une source constante de satisfaction. "},
            creativity: {title: "Créativité", text: "La créativité est la force motrice derrière mon choix de carrière dans le développement front-end. Le web offre une toile vierge infinie, une opportunité constante de créer et d'innover. Chaque projet représente pour moi un terrain de jeu où je peux donner vie à des idées originales et concevoir des expériences uniques.  J'aime explorer les intersections entre la technologie et la créativité, car c'est là que naissent les solutions novatrices. Pour moi, le développement web est bien plus qu'une suite de compétences techniques ; c'est une aventure créative où chaque défi est une opportunité d'imaginer, de construire et d'innover. "},
            global: {title: "Vision Globale", text: "Détenir une vision holistique des projets constitue le socle de ma philosophie de travail. Pour moi, la communication est l'épine dorsale d'un projet tech réussi. J'attache une importance particulière à être impliquée à toutes les étapes du processus de développement d'un projet web. De la conception initiale à la mise en production, je m'efforce d'être une présence active et contributive. La communication fluide, tant avec les membres de l'équipe que les parties prenantes, est essentielle pour comprendre les besoins spécifiques, résoudre les problèmes de manière proactive et garantir la cohérence de la vision à travers chaque ligne de code. Être témoin de l'évolution complète d'un projet, du concept à la réalisation, est une source d'inspiration constante qui nourrit ma passion pour le développement web. Cette approche collaborative permet de transformer des idées en solutions concrètes, créant ainsi des expériences numériques qui vont au-delà des attentes."},
        },
        en: {
            title: "My Vision",
            ux: {title: "User Experience", text: "In my approach to web development, User Experience (UX) takes center stage. By thoroughly understanding our audience, we can tailor every aspect of the website—from its architecture to aesthetics—to optimize usage for the target users. For me, UX isn’t just a goal; it’s a guiding principle that must be upheld throughout the entire development process. We should always keep in mind who our work is intended for. In summary, UX serves as the compass that directs every decision, ensuring an exceptional user experience."},
            accessibility: {title: "Accessibility", text: "At the heart of my approach to web development, there is a profound dedication to accessibility. Prioritizing inclusion is a fundamental aspect reflected in every facet of my projects. I envision the web as an egalitarian space, where individuals can seamlessly participate, navigate, and interact. As architects of the future web, we shoulder the responsibility to make it as inclusive and universally accessible as possible. With this perspective in mind, I am committed to crafting digital experiences that not only embrace diversity but also ensure fair and equal access for all."},
            clean: {title: "Clean Code", text: "I firmly believe that the pillars of a robust web project lie in readability, simplicity, testability, and reusability. Code clarity, far from being a purely aesthetic consideration, is a fundamental functional imperative. A clean codebase not only enhances collaboration but also streamlines debugging and ensures long-term maintainability. I am dedicated to continual improvement in this discipline, recognizing that the quality of code extends beyond the mere execution of technical tasks. It is a pivotal factor in crafting technological solutions that are not only efficient but also sustainable and high-performing."},
            learning: {title: "The Passion of Learning", text: "My passion for web development is fueled by an insatiable thirst for learning. In this dynamic and ever-evolving realm, each day presents a new opportunity to explore emerging technologies, refine my skills, and tackle stimulating challenges. Having transitioned careers myself, I am intimately aware of the inherent challenges of this journey. That's why, for nearly a year, I have been dedicated to mentoring individuals undergoing career change into web development. I find great satisfaction in sharing my knowledge, simplifying technical concepts, and guiding my mentees through their learning journeys. Pedagogy is an essential component of this commitment, and witnessing the progress of those I mentor is a constant source of fulfillment."},
            creativity: {title: "Creativity", text: "Creativity is the driving force behind my choice of a career in front-end development. The web provides a constant opportunity to create and innovate. Each project becomes my playground—a space to infuse life into original concepts and craft unique user experiences. I enjoy exploring the intersections between technology and creativity, as it is at these crossroads that innovative solutions are born. To me, web development is more than just a set of technical skills; it is a creative adventure where every challenge becomes an opportunity to imagine, build, and innovate."},
            global: {title: "Holistic View", text: "Possessing a holistic view of projects forms the foundation of my work philosophy. To me, communication is the backbone of a successful tech project. I place special emphasis on being involved in every stage of the web development process. From the initial concept to production, I strive to be an active and contributing presence. Smooth communication, both with team members and stakeholders, is crucial for understanding specific needs, proactively resolving issues, and ensuring the coherence of the vision throughout every line of code. Being witness to the complete evolution of a project is a constant source of inspiration that fuels my passion for web development. This collaborative approach transforms ideas into tangible solutions, creating digital experiences that exceed expectations."},
        },
    };
    
    const translation = translations[language];

    return (
        <section id="pVision" className="pVision">
            <h2>{translation.title}</h2>
            <div className="pVision--skills">
                <div>
                    <h3>{translation.ux.title}</h3>
                    <p>{translation.ux.text}</p>
                    <div><span>1</span></div>
                </div>
                <div>
                    <h3>{translation.clean.title}</h3>
                    <p>{translation.clean.text}</p>
                    <div><span>2</span></div>
                </div>
                <div>
                    <h3>{translation.accessibility.title}</h3>
                    <p>{translation.accessibility.text}</p>
                    <div><span>3</span></div>
                </div>
                <div>
                    <h3>{translation.learning.title}</h3>
                    <p>{translation.learning.text}</p>
                    <div><span>4</span></div>
                </div>
                <div>
                    <h3>{translation.creativity.title}</h3>
                    <p>{translation.creativity.text}</p>
                    <div><span>5</span></div>
                </div>
                <div>
                    <h3>{translation.global.title}</h3>
                    <p>{translation.global.text}</p>
                    <div><span>6</span></div>
                </div>
            </div>
        </section>
    );
}

export default Vision;