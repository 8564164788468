import yoga from "../assets/images/hobbies/yoga.png"
import yogaWeb from "../assets/images/hobbies/yoga.webp"
import videoGames from "../assets/images/hobbies/videogames.png"
import videoGamesWeb from "../assets/images/hobbies/videogames.webp"
import travel from "../assets/images/hobbies/travel.png"
import travelWeb from "../assets/images/hobbies/travel.webp"
import swimming from "../assets/images/hobbies/swimming.png"
import swimmingWeb from "../assets/images/hobbies/swimming.webp"
import singing from "../assets/images/hobbies/singing.png"
import singingWeb from "../assets/images/hobbies/singing.webp"
import selfCare from "../assets/images/hobbies/selfcare.png"
import selfCareWeb from "../assets/images/hobbies/selfcare.webp"
import reading from "../assets/images/hobbies/reading.png"
import readingWeb from "../assets/images/hobbies/reading.webp"
import movies from "../assets/images/hobbies/movies.png"
import moviesWeb from "../assets/images/hobbies/movies.webp"
import japan from "../assets/images/hobbies/japan.png"
import japanWeb from "../assets/images/hobbies/japan.webp"
import horse from "../assets/images/hobbies/horse.png"
import horseWeb from "../assets/images/hobbies/horse.webp"
import game from "../assets/images/hobbies/game.png"
import gameWeb from "../assets/images/hobbies/game.webp"
import escapeGame from "../assets/images/hobbies/escapegame.png"
import escapeGameWeb from "../assets/images/hobbies/escapegame.webp"
import drawing from "../assets/images/hobbies/drawing.png"
import drawingWeb from "../assets/images/hobbies/drawing.webp"
import dog from "../assets/images/hobbies/dog.png"
import dogWeb from "../assets/images/hobbies/dog.webp"
import cooking from "../assets/images/hobbies/cooking.png"
import cookingWeb from "../assets/images/hobbies/cooking.webp"
import blog from "../assets/images/hobbies/blog.png"
import blogWeb from "../assets/images/hobbies/blog.webp"

const hobbies = [
    {
        nameFr: "Je fais du Yoga",
        nameEn: "I Do Yoga",
        altFr: "yoga",
        altEn: "yoga",
        illustration: yoga,
        illustrationWeb: yogaWeb
    },
    {
        nameFr: "Je Joue aux Jeux Vidéo",
        nameEn: "I play Video Games",
        altFr: "Manette Jeux Vidéo",
        altEn: "Joystick Video Games",
        illustration: videoGames,
        illustrationWeb: videoGamesWeb
    },
    {
        nameFr: "Je Voyage",
        nameEn: "I Travel",
        altFr: "Valise de voyage",
        altEn: "Travel suitcase",
        illustration: travel,
        illustrationWeb: travelWeb
    },
    {
        nameFr: "Je Nage",
        nameEn: "I swim",
        altFr: "Nageur",
        altEn: "Swimmer",
        illustration: swimming,
        illustrationWeb: swimmingWeb
    },
    {
        nameFr: "Je Chante (faux)",
        nameEn: "I Sing (off-key)",
        altFr: "Coeur chanteur",
        altEn: "Singing heart",
        illustration: singing,
        illustrationWeb: singingWeb
    },
    {
        nameFr: "Je fais du Développement Personnel",
        nameEn: "I do Personal Growth",
        altFr: "Développement Personnel",
        altEn: "Personal Growth",
        illustration: selfCare,
        illustrationWeb: selfCareWeb
    },
    {
        nameFr: "Je Lis",
        nameEn: "I Read",
        altFr: "Livres",
        altEn: "Books",
        illustration: reading,
        illustrationWeb: readingWeb
    },
    {
        nameFr: "Je regarde des Films",
        nameEn: "I watch Movies",
        altFr: "Films",
        altEn: "Movies",
        illustration: movies,
        illustrationWeb: moviesWeb
    },
    {
        nameFr: "Je m'intéresse à la culture Japonaise",
        nameEn: "I learn about Japanese culture",
        altFr: "Chat de chance japonais",
        altEn: "Lucky japanese cat",
        illustration: japan,
        illustrationWeb: japanWeb
    },
    {
        nameFr: "Je monte à Cheval",
        nameEn: "I do Horse Riding",
        altFr: "Cheval",
        altEn: "Horse",
        illustration: horse,
        illustrationWeb: horseWeb
    },
    {
        nameFr: "Je joue aux Jeux de Société",
        nameEn: "I play Board Games",
        altFr: "Dés",
        altEn: "Dices",
        illustration: game,
        illustrationWeb: gameWeb
    },
    {
        nameFr: "Je fais des Escape Games",
        nameEn: "I play Escape Games",
        altFr: "Escape Games",
        altEn: "Escape Games",
        illustration: escapeGame,
        illustrationWeb: escapeGameWeb
    },
    {
        nameFr: "Je Dessine",
        nameEn: "I Draw",
        altFr: "Dessin de fleurs",
        altEn: "Flower drawing",
        illustration: drawing,
        illustrationWeb: drawingWeb
    },
    {
        nameFr: "Je passe du temps avec mes Chiens",
        nameEn: "I spend time with my Dogs",
        altFr: "Chien",
        altEn: "Dog",
        illustration: dog,
        illustrationWeb: dogWeb
    },
    {
        nameFr: "Je Cuisine",
        nameEn: "I Cook",
        altFr: "Ustensiles de cuisine",
        altEn: "Cooking tools",
        illustration: cooking,
        illustrationWeb: cookingWeb
    },
    {
        nameFr: "J'écris des Articles",
        nameEn: "I write Articles",
        altFr: "Blog",
        altEn: "Blog",
        illustration: blog,
        illustrationWeb: blogWeb
    },
]

export default hobbies