import React from 'react';
import {useState} from "react";

import { useLanguage } from '../../contexts/LanguageContext';
import {Link as ScrollLink} from "react-scroll";

import logo from "../../assets/images/logo.png";
import logoWeb from "../../assets/images/logo.webp";

function Header() {

    const [isMobileOpen, setMobileOpen] = useState(false);

    const { language, switchToEnglish, switchToFrench } = useLanguage();

    const translations = {
        en: {
            menuAbout: 'About',
            menuExperience: 'Experiences',
            menuSkills: 'Skills',
            menuProjects: 'Projects',
        },
        fr: {
            menuAbout: 'À propos',
            menuExperience: 'Expériences',
            menuSkills: 'Compétences',
            menuProjects: 'Projets',
        },
    };

    const translation = translations[language];

    return (
        <header>
            <div className={isMobileOpen ? 'pMenu show' : 'pMenu'}>
                <div className="pMenu--mobile--bg"></div>
                <a href="#main" className="pMenu--logo" aria-label="Home" rel="nofollow">
                <picture>
                    <source srcSet={logoWeb} type="image/webp" height="90" width="110"/>
                    <source srcSet={logo} type="image/png" height="90" width="110"/>
                    <img src={logo} alt="logo Oliwia Web Developer" height="90" width="110"/>
                </picture>
                </a>
                <div className="pMenu--mobile" onClick={() => setMobileOpen(!isMobileOpen)}>
                    <div id="bar1" className="pMenu--mobile--bar"></div>
                    <div id="bar2" className="pMenu--mobile--bar"></div>
                    <div id="bar3" className="pMenu--mobile--bar"></div>
                </div>
                <nav className="pMenu--nav">
                     <ul>
                          <li>
                              <ScrollLink to="pAbout" spy={true} smooth={true} offset={-70} duration={500} rel="nofollow">
                                  {translation.menuAbout}
                              </ScrollLink>
                          </li>
                         <li>
                             <ScrollLink to="pExperiences" spy={true} smooth={true} offset={-70} duration={500} rel="nofollow">
                                 {translation.menuExperience}
                             </ScrollLink>
                         </li>
                         <li>
                             <ScrollLink to="pSkills" spy={true} smooth={true} offset={-70} duration={500} rel="nofollow">
                                 {translation.menuSkills}
                             </ScrollLink>
                         </li>
                         <li>
                             <ScrollLink to="pProjects" spy={true} smooth={true} offset={-70} duration={500} rel="nofollow">
                                 {translation.menuProjects}
                             </ScrollLink>
                         </li>
                         <li>
                             <ScrollLink to="pContact" spy={true} smooth={true} offset={-70} duration={500} rel="nofollow">
                                 Contact
                             </ScrollLink>
                         </li>
                     </ul>
                </nav>
                <div className="pMenu--lang">
                    <span onClick={switchToEnglish} className={language === "en" ? "active" : ""}>EN</span>
                    <span>/</span>
                    <span onClick={switchToFrench} className={language === "fr" ? "active" : ""}>FR</span>
                </div>
            </div>
        </header>
    );
}

export default Header;