import Header from "./Header/Header";
import Main from "./Main/Main";
import About from "./About/About";
import Experience from "./Experience/Experience"
import Skills from "./Skills/Skills";
import Vision from "./Vision/Vision";
import Projects from "./Projects/Projects";
//import Blog from "./Blog/Blog";
import Contact from "./Contact/Contact";
import Footer from "./Footer/Footer";

function App() {
  return (
    <>
        <Header/>
        <Main/>
        <About/>
        <Experience/>
        <Skills/>
        <Vision/>
        <Projects/>
        <Contact/>
        <Footer/>
    </>
  );
}

export default App;
