import React, { useEffect, useState } from "react";

import useSound from "use-sound";

import { FaPlay, FaPause } from "react-icons/fa6";
import music from "../../assets/music/dorothea.mp3";

function Player() {

  const [isPlaying, setIsPlaying] = useState(false);
  const [seconds, setSeconds] = useState(0);
  const [play, { pause, duration, sound }] = useSound(music);

  const [currTime, setCurrTime] = useState({
    min: "00",
    sec: "00",
  });

  const [time, setTime] = useState({
    min: "00",
    sec: "00",
  });

  const playingButton = () => {
    if (isPlaying) {
      pause();
    } else {
      play();
    }
    setIsPlaying(!isPlaying);
  };

  useEffect(() => {
    if (duration) {
      const sec = duration / 1000;
      const min = Math.floor(sec / 60);
      const secRemain = Math.floor(sec % 60);
      setTime({
        min: String(min).padStart(2, "0"),
        sec: String(secRemain).padStart(2, "0"),
      });
    }
  }, [duration, isPlaying]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (sound) {
        setSeconds(sound.seek([]));
        const min = Math.floor(sound.seek([]) / 60);
        const sec = Math.floor(sound.seek([]) % 60);
        setCurrTime({
          min: String(min).padStart(2, "0"),
          sec: String(sec).padStart(2, "0"),
        });
      }
    }, 1000);
    return () => clearInterval(interval);
  }, [sound]);

  return (
    <div className="player">
      <div>
        <h3 className="title">Dorothea</h3>
        <p className="subTitle">Taylor Swift</p>
      </div>
      <div className="player--time">
        <div>
          <p>
            {currTime.min}:{currTime.sec}
          </p>
          <p>
            {time.min}:{time.sec}
          </p>
        </div>
        <input
          type="range"
          min="0"
          max={duration / 1000}
          value={seconds}
          className="timeline"
          onChange={(e) => {
            sound.seek([e.target.value]);
          }}
          aria-label="Music Progress Slider"
        />
      </div>
      <div className="player--btn">
        {!isPlaying ? (
          <button className="playButton" aria-label="Play music" onClick={playingButton}>
            <FaPlay />
          </button>
        ) : (
          <button className="playButton" aria-label="Pause music" onClick={playingButton}>
            <FaPause />
          </button>
        )}
      </div>
    </div>
  );
}

export default Player;