import React, { useState, useEffect } from 'react';

import { useLanguage } from '../../contexts/LanguageContext';

import Player from "../Player/Player";

import hobbies from '../../data/hobbies';

import music from "../../assets/images/music.png";
import musicWeb from "../../assets/images/music.webp";

function About() {

    const [weather, setWeather] = useState();
    const [weatherMood, setWeatherMood] = useState();
    const [error, setError] = useState(null);
    const [hobbiesList, setHobbiesList] = useState([]);

    const updateHobbiesList = () => {
      const newHobbiesList = hobbies.slice(0, 4).map(function () {
          return this.splice(Math.floor(Math.random() * this.length), 1)[0];
      }, hobbies.slice());
      setHobbiesList(newHobbiesList);
    };

    useEffect(() => {
      updateHobbiesList();
    }, []);

    const getHobbies = () => {
        let result = hobbies.slice(0, 4).map(function () {
            return this.splice(Math.floor(Math.random() * this.length), 1)[0];
        }, hobbies.slice());
        setHobbiesList(result)
    }

    const weatherAPIUrl = "https://api.openweathermap.org/data/2.5/weather?q=Le Perreux-sur-Marne&appid=bfb2520eea1316d617e6363687d72d8f"

    useEffect(() => {
        const fetchWeather = async () => {
          try {
            const response = await fetch(weatherAPIUrl);
            const data = await response.json();
    
            setWeather(Math.round(data.main.temp - 273.15));
    
            const currentTime = new Date().getHours();
    
            if (data.weather[0].main === "Clear") {
              if (currentTime >= 20 || currentTime < 6) {
                setWeatherMood("🌙");
              } else {
                setWeatherMood("☀️");
              }
            } else if (data.weather[0].main === "Thunderstorm") {
              setWeatherMood("⛈️");
            } else if (data.weather[0].main === "Drizzle") {
              setWeatherMood("🌧️");
            } else if (data.weather[0].main === "Rain") {
              setWeatherMood("☔");
            } else if (data.weather[0].main === "Snow") {
              setWeatherMood("❄️");
            } else if (data.weather[0].main === "Clouds") {
              setWeatherMood("☁️");
            } else {
              setWeatherMood("🌬️");
            }
          } catch (error) {
            //console.log(error);
            setError("Une erreur s'est produite lors de la récupération des données météorologiques.");
          }
        };
    
        fetchWeather();
    }, []);

    const { language } = useLanguage();

    const translations = {
        en: {
            title: 'About',
            aboutText: ["After studying and working in the legal field, I embarked on an exciting career change towards web development, a discovery that marked the culmination of several years of professional questioning. Driven by my curiosity and creativity, I oriented myself towards front-end development, mostly because I like to use my empathy to design interfaces that meet the expectations of users and offer them enriching interactive experiences.", "Thanks to my background, I have a strong ability to adapt and great determination. I enjoy working with agility and being involved in every stage of a project. Autonomous, organized and attentive to detail, I want to promote the best practices of web development.", "During our collaboration, I will be able to mobilize both my technical skills and my personal qualities to carry out our projects successfully."],
            musicAbout: "In the coding flow, I've got playing in my headphones:",
            localisationAbout: "Currently coding and creating awesome things from ",
            hobbyTitle: "When I’m not coding:",
            hobbyBtn: "Explore my other interests!"
        }, 
        fr: {
            title: 'À Propos',
            aboutText: ["Après des études et un premier emploi dans le domaine juridique, j'ai entrepris une reconversion professionnelle passionnante vers le développement Web, une découverte qui a marqué le point culminant de plusieurs années de questionnement professionnel. Animée par ma curiosité et ma créativité, je me suis orientée vers le développement front-end, car j’aime mettre à profit mon sens de l’empathie pour concevoir des interfaces adaptées aux attentes des utilisateurs et leur offrir des expériences interactives enrichissantes.", "De par mon parcours, je fais preuve d’une forte capacité d’adaptation et d’une grande détermination. J’apprécie de travailler avec agilité et de participer à toutes les étapes d’un projet. Autonome, organisée et soucieuse du détail, je veux promouvoir les bonnes pratiques du développement web.", "Lors de notre collaboration, je saurai mobiliser aussi bien mes compétences techniques, que mes qualités personnelles afin de mener à bien nos projets."],
            musicAbout: "En ce moment quand je code dans mes écouteurs il y a :",
            localisationAbout: "Actuellement en train de coder et créer des choses géniales depuis ",
            hobbyTitle: "Quand je ne code pas :",
            hobbyBtn: "Découvrez mes autres passions !"
        },
    };
  
    const translation = translations[language];

    return (
        <section className="pAbout">
            <h2>{translation.title}</h2>
            <div className="pAbout--main">
                <div className="pAbout--main--music">
                    <picture>
                      <source srcSet={musicWeb} type="image/webp" height="500" width="500"/>
                      <source srcSet={music} type="image/png" height="500" width="500"/>
                      <img src={music} alt={"illustration personne écoutant de la musique"} height="500" width="500"></img>
                    </picture>
                    <p>{translation.musicAbout}</p>
                    <Player/>
                </div>
                <div className="pAbout--main--infos">
                    <div>
                        <p>{translation.aboutText[0]}</p>
                        <p>{translation.aboutText[1]}</p>
                        <p>{translation.aboutText[2]}</p>
                        {error ? null : (
                            <div className="pAbout--main--infos--weather">
                            {translation.localisationAbout}Le Perreux-sur-Marne ({weather}°C) {weatherMood} 
                            </div>
                        )}
                    </div>
                    <div className="pAbout--main--infos--hobbies">
                        <h3>{translation.hobbyTitle}</h3>
                        <div>
                            <div>
                                {hobbiesList.map((hobby, index) => {
                                    return (
                                        <div key={index} className="pAbout--main--infos--hobbies--container">
                                            <div>
                                              <picture>
                                                <source srcSet={hobby.illustrationWeb} type="image/webp" height="120" width="100"/>
                                                <source srcSet={hobby.illustration} type="image/png" height="120" width="100"/>
                                                <img src={hobby.illustration} alt={language === "en" ? hobby.altEn : hobby.altFr} height="120" width="100"></img>
                                              </picture>
                                            </div>
                                            <p>{language === "en" ? hobby.nameEn : hobby.nameFr}</p>
                                        </div>
                                    );
                                })}
                            </div>
                            <div className="pAbout--main--infos--hobbies--btn">
                                <button onClick={getHobbies} aria-label="Load new hobbies">{translation.hobbyBtn}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default About;