import React from 'react';

import { useLanguage } from '../../contexts/LanguageContext';

import { Link as ScrollLink } from 'react-scroll';

import { FaSquareXTwitter, FaRegPenToSquare, FaGithub, FaLinkedin } from "react-icons/fa6";
import me from "../../assets/images/me.png";
import meWeb from "../../assets/images/me.webp";
import arrow from "../../assets/images/arrow.png";
import arrowWeb from "../../assets/images/arrow.webp";

function Main() {

    ScrollLink.defaultProps = {
        smooth: true,
        duration: 500,
        offset: -70,
    };

    const { language } = useLanguage();
    
    const translations = {
        fr: {
            title: "Bienvenue",
            presentation: 'Moi, c’est Oliwia, je suis développeuse Front-End. J’aime marier la créativité au code pour concevoir des sites Web uniques. Mon parcours, complété par une véritable soif d’apprendre, m’a permis de développer de nombreuses compétences techniques que je mets en pratique dans mes projets. Passionnée, j’aime partager mon amour du développement Web.',
            me: "C'est moi",
            catch: 'Faisons équipe pour donner vie à tous vos projets !',
            cta: "Contactez-moi",
            imgAlt: "Développeur Front-End - Oliwia Rutkowska",
            arrowAlt: "Développeur Front-End - Flèche"
        },
        en: {
            title: "Welcome",
            presentation: 'I\'m Oliwia, a Front-End developer. I enjoy blending creativity with code to craft unique websites. My journey, fueled by a genuine desire to always learn more, has enabled me to develop a wide range of technical skills that I apply in my projects. I also love to share my passion for web development.',
            me: "It's me",
            catch: 'Let\'s team up to bring all your projects to life!',
            cta: "Contact me",
            imgAlt: "Front-End Developer - Oliwia Rutkowska",
            arrowAlt: "Développeur Front-End - Arrow"
        },
    };

    const translation = translations[language];
    
    return (
        <section className="pMain">
            <div className="pMain--section">
                <div className="pMain--section--text">
                    <h1>{translation.title}</h1>
                    <p>{translation.presentation}</p>
                    <p>{translation.catch}</p>
                    <div id="btn__container">
                        <ScrollLink to="pContact" spy={true} smooth={true} offset={-70} duration={500} rel="nofollow">
                            <button className="contactMeBtn" aria-label="Scroll to contact form">
                                <span className="circle" aria-hidden="true">
                                    <span className="icon arrow"></span>
                                </span>
                                <span className="btn__text">{translation.cta}</span>
                            </button>
                        </ScrollLink>
                    </div>
                </div>
                <div className="pMain--section--pic">
                    <div className="pMain--section--pic--arrow">
                        <picture>
                            <source srcSet={arrowWeb} type="image/webp" height="120" width="120"/>
                            <source srcSet={arrow} type="image/png" height="120" width="120"/>
                            <img src={arrow} alt={translation.arrowAlt} height="120" width="120"/>
                        </picture>
                        <span>{translation.me}</span>
                    </div>
                    <div className="pMain--section--pic--border"></div>
                    <div className="pMain--section--pic--container">
                        <picture>
                            <source srcSet={meWeb} type="image/webp" height="640" width="400"/>
                            <source srcSet={me} type="image/png" height="640" width="400"/>
                            <img src={me} alt={translation.imgAlt} height="640" width="400"></img>
                        </picture>
                    </div>
                </div>
            </div>
            <div className="pMain--banner">
                <ul className="social-icons">
                    <li>
                        <a href="https://www.linkedin.com/in/oliwia-rutkowska/" target="_blank" rel="noreferrer" aria-label="My linkedin"><FaLinkedin/></a>
                    </li>
                    <li>
                        <a href="https://pixelprincess.fr/" target="_blank" rel="noreferrer" aria-label="My blog"><FaRegPenToSquare/></a>
                    </li>
                    <li>
                        <a href="https://github.com/Oliwia789" target="_blank" rel="noreferrer" aria-label="My github"><FaGithub/></a>
                    </li>
                    <li>
                        <a href="https://twitter.com/pxl_princess" target="_blank" rel="noreferrer" aria-label="My X"><FaSquareXTwitter/></a>
                    </li>
                </ul>
            </div>
        </section>
    );
}

export default Main;