import { useLanguage } from '../../contexts/LanguageContext';

import {Link as ScrollLink} from "react-scroll";

import { FaEnvelopeCircleCheck, FaPhone, FaSquareXTwitter, FaGithub, FaLinkedin } from "react-icons/fa6";
import logo from "../../assets/images/logo.png";

function Footer() {

    const { language } = useLanguage();

    const translations = {
        en: {
            catchTitle: "Let's stay in touch",
            menuHome: "Home",
            menuAbout: 'About',
            menuExperience: 'Experiences',
            menuSkills: 'Skills',
            menuProjects: 'Projects',
        },
        fr: {
            catchTitle: "Restons en contact",
            menuHome: "Accueil",
            menuAbout: 'À propos',
            menuExperience: 'Expériences',
            menuSkills: 'Compétences',
            menuProjects: 'Projets',
        },
    };

    const translation = translations[language];

    return (
        <footer className='footer'>
            <div className='footer__info'>
                <div className='footer__info__contact pContact--infos'>
                    <h1>{translation.catchTitle}</h1>
                    <div>
                        <a href='mailto:oliwia.r789@gmail.com' target="_blank" rel="noreferrer" className='pContact--infos--item' aria-label="My email">
                            <span className='pContact--infos--item--icon'><FaEnvelopeCircleCheck /></span>
                            <span className='pContact--infos--item--text pContact--infos--item--text--link'>oliwia.r789@gmail.com</span>
                        </a>
                        <a href='tel:0621703480' target="_blank" rel="noreferrer" className='pContact--infos--item' aria-label="My phone number">
                            <span className='pContact--infos--item--icon'><FaPhone /></span>
                            <span className='pContact--infos--item--text pContact--infos--item--text--link'>+33 6 21 70 34 80</span>
                        </a>
                    </div>
                </div>
                <div className='footer__info__socials'>
                    <ul className='social-icons'>
                        <li>
                            <a href="https://www.linkedin.com/in/oliwia-rutkowska/" target="_blank" rel="noreferrer" aria-label="My linkedin"><FaLinkedin/></a>
                        </li>
                        <li>
                            <a href="https://github.com/Oliwia789" target="_blank" rel="noreferrer" aria-label="My github"><FaGithub/></a>
                        </li>
                        <li>
                            <a href="https://twitter.com/pxl_princess" target="_blank" rel="noreferrer" aria-label="My X"><FaSquareXTwitter/></a>
                        </li>
                    </ul>
                </div>    
                <div className='footer__info__logo'>
                    <img src={logo} alt="logo Oliwia Web Developer" height="140" width="140"/>
                </div>
            </div>
            <div className='footer__menu'>
                <nav>
                     <ul>
                        <li>
                            <ScrollLink to="pMain" spy={true} smooth={true} offset={-70} duration={500} rel="nofollow">
                            {translation.menuHome}
                            </ScrollLink>
                        </li>
                          <li>
                              <ScrollLink to="pAbout" spy={true} smooth={true} offset={-70} duration={500} rel="nofollow">
                              {translation.menuAbout}
                              </ScrollLink>
                          </li>
                         <li>
                             <ScrollLink to="pExperiences" spy={true} smooth={true} offset={-70} duration={500} rel="nofollow">
                             {translation.menuExperience}
                             </ScrollLink>
                         </li>
                         <li>
                             <ScrollLink to="pSkills" spy={true} smooth={true} offset={-70} duration={500} rel="nofollow">
                             {translation.menuSkills}
                             </ScrollLink>
                         </li>
                         <li>
                             <ScrollLink to="pProjects" spy={true} smooth={true} offset={-70} duration={500} rel="nofollow">
                             {translation.menuProjects}
                             </ScrollLink>
                         </li>
                         <li>
                             <ScrollLink to="pContact" spy={true} smooth={true} offset={-70} duration={500} rel="nofollow">
                                 Contact
                             </ScrollLink>
                         </li>
                     </ul>
                </nav>
            </div>
        </footer>
    );
}

export default Footer;