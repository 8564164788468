import React, { useState} from 'react';

import { useLanguage } from '../../contexts/LanguageContext';

import experiences from '../../data/experiences'

import cv from '../../assets/images/cv.png';
import cvWeb from '../../assets/images/cv.webp';
import CvFr from '../../assets/cv/CV-FR.pdf';
import CvEn from '../../assets/cv/CV-EN.pdf';
import { FaAngleDown } from "react-icons/fa6";

function Experience() {

    const [active, setActive] = useState(1);
    const [activeMob, setActiveMob] = useState(null);

    const handleToggle = (id) => {
        setActiveMob((prevActiveMob) => (prevActiveMob === id ? null : id));
    };

    const { language } = useLanguage();

    const translations = {
        en: {
            title: 'Experience'
        }, 
        fr: {
            title: 'Expérience',
        },
    };

    const translation = translations[language];

    const cvFile = language === 'fr' ? CvFr : CvEn;

    return (
        <section id="pExperiences" className="pExperiences">
            <h2>{translation.title}</h2>
            <a href={cvFile} download aria-label="Download my cv">
                <picture>
                    <source srcSet={cvWeb} type="image/webp" height="200" width="200"/>
                    <source srcSet={cv} type="image/png" height="200" width="200"/>
                    <img className="pExperiences--cv" src={cv} alt='cv button' height="200" width="200"></img>
                </picture>
            </a>
            <div className="pExperiences--main">
                <div className="pExperiences--main--graph">
                {experiences.map((exp, index) => {
                    return(
                        <div key={`exp-${exp.id}`} className={active === exp.id ? "active" : ""} onClick={() => setActive(exp.id)}>
                            <p>{exp[language].title}</p>
                        </div>
                    )
                    })}
                </div>
                <div className="pExperiences--main--descr">
                    {experiences.map((exp) => {
                        if(exp.id === active) {
                            return (
                                <div key={`exp-descr-${exp.id}`}>
                                    <p className="pExperiences--main--descr--date">{exp[language].date}</p>
                                    {exp[language].descriptions.map((description, index) => {
                                        return (<p key={`exp-descr-${exp.id}-${index}`} className="pExperiences--main--descr--text">{description}</p>)
                                    })}
                                    <div className="pExperiences--main--descr--skills">
                                    {
                                    exp[language].skills.map((skill, index) => {
                                        return (<p key={`exp-skill-${exp.id}-${index}`} className="pExperiences--main--descr--skill">{skill}</p>)
                                    })
                                    }
                                    </div>
                                </div>
                            );
                        }
                        return null;
                    })}
                </div>
            </div>
            <div className="pExperiences--main--mob">
                <div className="pExperiences--main--graph">
                {experiences.map((exp) => {
                    return(
                        <div key={`exp-mob-${exp.id}`} className={activeMob === exp.id ? "active" : ""} onClick={() => handleToggle(exp.id)}>
                            <div>
                                <p>{exp[language].date}</p>
                                <span><FaAngleDown /></span>
                            </div>
                            {exp.id === activeMob && (
                                <>
                                {exp[language].descriptions.map((description, index) => (
                                    <p key={`exp-mob-descr-${exp.id}-${index}`} className="pExperiences--main--descr--text">
                                        {description}
                                    </p>
                                ))}
                                <div className="pExperiences--main--descr--skills">
                                    {exp[language].skills.map((skill, index) => (
                                        <p key={`exp-mob-skill-${exp.id}-${index}`} className="pExperiences--main--descr--skill">
                                            {skill}
                                        </p>
                                    ))}
                                </div>
                                </>
                            )}
                        </div>
                    )
                    })}
                </div>
            </div>
            <div></div>
        </section>
    );
}

export default Experience;