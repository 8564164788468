import React, { useState } from 'react';

import { useLanguage } from '../../contexts/LanguageContext';

import CvFr from '../../assets/cv/CV-FR.pdf';
import CvEn from '../../assets/cv/CV-EN.pdf';
import { FaEnvelopeCircleCheck, FaPhone, FaFileArrowDown, FaGithub, FaLinkedin, FaMessage } from "react-icons/fa6";

function Contact() {

    const [formStatus, setFormStatus] = useState(null);

    const [formData, setFormData] = useState({
        name: '',
        email: '',
        message: '',
    });

    const [formErrors, setFormErrors] = useState({
        name: '',
        email: '',
        message: '',
    });

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    const validateName = (name) => {
        const trimmedName = name.trim();
        if (trimmedName.length === 0) {
            return translation.validationErrors.name.required;
        }
        if (trimmedName.length < 3 || trimmedName.length > 50) {
            return translation.validationErrors.name.length;
        }
        const nameRegex = /^[A-Za-z\s-']+$/;
        if (!nameRegex.test(trimmedName)) {
            return translation.validationErrors.name.valid;
        }
        return '';
    };

    const validateEmail = (email) => {
        const trimmedEmail = email.trim();
        if (trimmedEmail.length === 0) {
            return translation.validationErrors.email.required;
        }
        if (trimmedEmail.length < 5 || trimmedEmail.length > 80) {
            return translation.validationErrors.email.length;
        }
        if (!emailRegex.test(trimmedEmail)) {
            return translation.validationErrors.email.valid;
        }
        return '';
    };

    const validateTextarea = (message) => {
        const trimmedMessage = message.trim();
        if (trimmedMessage.length === 0) {
            return translation.validationErrors.message.required;
        }
        if (trimmedMessage.length < 10 || trimmedMessage.length > 400) {
            return translation.validationErrors.message.length;
        }
        const hasHtmlOrScriptTags = /<[^>]*>|&[^;]+;/g.test(trimmedMessage);
        if (hasHtmlOrScriptTags) {
            return translation.validationErrors.message.valid;
        }
        return '';
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        let error = '';
        switch (name) {
            case 'name':
                error = validateName(value);
                break;
            case 'email':
                error = validateEmail(value);
                break;
            case 'message':
                error = validateTextarea(value);
                break;
            default:
                break;
        }
        setFormErrors({ ...formErrors, [name]: error });
        setFormData({ ...formData, [name]: value });
    };

    const isValidForm = () => {
        return (
            formErrors.name === '' &&
            formErrors.email === '' &&
            formErrors.message === '' &&
            formData.name.trim() !== '' &&
            formData.email.trim() !== '' &&
            emailRegex.test(formData.email) &&
            formData.message.trim() !== ''
        );
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!isValidForm()) {
            console.log("d")
            setFormStatus('invalid');
            return;
        }
        try {
            const response = await fetch(window.location.origin+'/.netlify/functions/contact', {
                method: 'POST',
                body: JSON.stringify(formData),
            });

            if (response.status === 200) {
                setFormStatus('success');
            } else {
                setFormStatus('error');
            }
        } catch (error) {
            console.error(error);
            setFormStatus('error');
        }
    };

    const { language } = useLanguage();

    const translations = {
        fr: {
            title: `Contactez-moi`,
            button: "Envoyer",
            placeholders: {
                name: "Votre Nom",
                email: "Votre Email",
                message: "Votre Message"
            },
            validationErrors: {
                name: {
                    required: "Veuillez entrer un nom",
                    length: "Le nom doit contenir entre 3 et 50 caractères",
                    valid: "Veuillez entrer un nom valide",
                },
                email: {
                    required: "Veuillez entrer une adresse e-mail",
                    length: "L'adresse e-mail doit contenir entre 5 et 80 caractères",
                    valid: "Veuillez entrer une adresse e-mail valide",
                },
                message: {
                    required: "Veuillez entrer un message",
                    length: "Le message doit contenir entre 10 et 400 caractères",
                    valid: "Le message ne doit pas contenir de balises HTML ou de scripts",
                },
            },
            formMsg: {
                invalid: "Veuillez remplir correctement tous les champs du formulaire",
                error: "Oups ! Une erreur s'est produite. Veuillez réessayer plus tard",
                success: "Votre message a été envoyé avec succès !",
            },
            infos: {
                cv: "Téléchargez mon CV",
                github: "Visitez mon GitHub",
                linkedin: "Visitez mon Linkedin",
                languages: "Polonais (langue maternelle) / Français / Anglais"
            }
        },
        en: {
            title: "Contact Me",
            button: "Send",
            placeholders: {
                name: "Your Name",
                email: "Your Email",
                message: "Your Message"
            },
            validationErrors: {
                name: {
                    required: "Please enter a name",
                    length: "Name should be between 3 and 50 characters",
                    valid: "Please enter a valid name",
                },
                email: {
                    required: "Please enter an email address",
                    length: "Email address should be between 5 and 80 characters",
                    valid: "Please enter a valid email address",
                },
                message: {
                    required: "Please enter a message",
                    length: "Message should be between 10 and 400 characters",
                    valid: "Message should not contain HTML or script tags",
                },
            },
            formMsg: {
                invalid: "Please ensure all fields are filled out correctly before submitting the form",
                error: "Oops! Something went wrong. Please try submitting the form again later.",
                success: "Your message has been sent successfully!",
            },
            infos: {
                cv: "Download my CV",
                github: "Visit my GitHub",
                linkedin: "Visit my Linkedin",
                languages: "Polish (native) / French / English"
            }
        },
    };

    const translation = translations[language];

    const cvFile = language === 'fr' ? CvFr : CvEn;

    return (
        <section className="pContact">
            <div className="pContact--infos">
                <h2>Oliwia Rutkowska</h2>
                <div>
                    <a href='mailto:oliwia.r789@gmail.com' target="_blank" rel="noreferrer" className='pContact--infos--item' aria-label="My email">
                        <span className='pContact--infos--item--icon'><FaEnvelopeCircleCheck /></span>
                        <span className='pContact--infos--item--text pContact--infos--item--text--link'>oliwia.r789@gmail.com</span>
                    </a>
                    <a href='tel:0621703480' target="_blank" rel="noreferrer" className='pContact--infos--item' aria-label="My phone number">
                        <span className='pContact--infos--item--icon'><FaPhone /></span>
                        <span className='pContact--infos--item--text pContact--infos--item--text--link'>+33 6 21 70 34 80</span>
                    </a>
                    <a href={cvFile} download className='pContact--infos--item' aria-label="Download my cv">
                        <span className='pContact--infos--item--icon'><FaFileArrowDown /></span>
                        <span className='pContact--infos--item--text pContact--infos--item--text--link'>{translation.infos.cv}</span>
                    </a>
                    <a href='https://github.com/Oliwia789' target="_blank" rel="noreferrer" className='pContact--infos--item' aria-label="My Github">
                        <span className='pContact--infos--item--icon'><FaGithub /></span>
                        <span className='pContact--infos--item--text pContact--infos--item--text--link'>{translation.infos.github}</span>
                    </a>
                    <a href='https://www.linkedin.com/in/oliwia-rutkowska/' target="_blank" rel="noreferrer" className='pContact--infos--item' aria-label="My linkedin">
                        <span className='pContact--infos--item--icon'><FaLinkedin /></span>
                        <span className='pContact--infos--item--text pContact--infos--item--text--link'>{translation.infos.linkedin}</span>
                    </a>
                    <div className='pContact--infos--item'>
                        <span className='pContact--infos--item--icon'><FaMessage /></span>
                        <span className='pContact--infos--item--text'>{translation.infos.languages}</span>
                    </div>
                </div>
            </div>
            <div className="pContact--form">
                <h2>{translation.title}</h2>
                <form onSubmit={handleSubmit} noValidate>
                    <div>
                        <div>
                            <input type="text" name="name" placeholder={translation.placeholders.name} value={formData.name} onChange={handleChange} aria-label="Name"></input>
                            {formErrors.name && <p className="error-message error-message-input">{formErrors.name}</p>}
                        </div>
                        <div>
                            <input type="email" name="email" placeholder={translation.placeholders.email} value={formData.email} onChange={handleChange} aria-label="Email"></input>
                            {formErrors.email && <p className="error-message error-message-input">{formErrors.email}</p>}
                        </div>
                    </div>
                    <div className="pContact--form--textarea">
                        <textarea name="message" placeholder={translation.placeholders.message} value={formData.message} onChange={handleChange} aria-label="Message"></textarea>
                        {formErrors.message && <p className="error-message">{formErrors.message}</p>}
                    </div>
                    <button type="submit" aria-label="Send message">{translation.button}</button>
                    {formStatus === 'success' && (
                        <p className="success-message">{translation.formMsg.success}</p>
                    )}
                    {formStatus === 'invalid' && (
                        <p className="error-message error-general-message">{translation.formMsg.invalid}</p>
                    )}
                    {formStatus === 'error' && (
                        <p className="error-message error-general-message">{translation.formMsg.error}</p>
                    )}
                </form>
            </div>
        </section>
    );
}

export default Contact;