import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './components/App';
import { LanguageProvider } from './contexts/LanguageContext';
import "./components/style/app.scss"

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
    <LanguageProvider>
        <App />
    </LanguageProvider>
);