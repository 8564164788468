
const experiences = [
    {
        id: 1,
        fr: {
            title: "Les Grappes / Plugwine",
            date: "CDI | Les Grappes | Depuis Août 2022",
            descriptions: ["En tant que développeuse front-end, j'ai contribué de manière significative à plusieurs projets axés sur l'amélioration et la résolution de bugs sur les sites existants pour du front et du back office utilisant différents langages (Vue.js, Angular, JS, TypeScript). Mon rôle a également inclus la refonte de certaines pages afin d’améliorer l'expérience utilisateur.", "Un aspect clé de mon travail a été l’élaboration et l’implémentation d’un design system harmonieux, garantissant une cohérence visuelle et une fluidité d’utilisation sur tous les sites. J’ai utilisé pour cela des outils et des technologies telles que Vue.js, Lit, Calcite, Stencil, TypeScript ou encore Storybook.", "En plus de mes responsabilités de développement, j'ai participé à la création de documentation technique détaillée pour assurer la compréhension et la maintenance efficace du code. Mon travail s'est déroulé dans un environnement agile, où la collaboration en équipe était primordiale."],
            skills: ["JavaScript", "Vue.js", "Angular", "PHP", "TypeScript", "Stencil.js", "Calcite Design System", "Storybook", "SCSS", "Figma", "Documentation technique", "Lit", "UX Design", "Responsive Design"]
        },
        en: {
            title: "Les Grappes / Plugwine",
            date: "Permanent Contract | Les Grappes | Since August 2022",
            descriptions: ["As a front-end developer, I actively participated in several projects aimed at optimizing and debugging existing websites for both front and back office using various languages (Vue.js, Angular, JS, TypeScript). I also revamped certain pages to enhance the user experience, incorporating best practices in ergonomics and responsive design.", "A crucial aspect of my role involved developing and implementing a cohesive design system to ensure visual consistency and usability across all websites. I utilized tools and technologies such as Vue.js, Lit, Calcite, Stencil, TypeScript, and Storybook.", "In addition to my development tasks, I contributed to the creation of detailed technical documentation to facilitate understanding and code maintenance. My work took place in an agile environment, where collaborative teamwork was paramount."],
            skills: ["JavaScript", "Vue.js", "Angular", "PHP", "TypeScript", "Stencil.js", "Calcite Design System", "Storybook", "SCSS", "Figma", "Technical documentation", "Lit", "UX Design", "Responsive Design"]
        },
    },
    {
        id: 2,
        fr: {
            title: "Quentoo",
            date: "Stage de Fin d'Études | Quentoo | Juin - Juillet 2022",
            descriptions: ["Lors de mon stage chez Quentoo (Pologne), j'ai eu l'opportunité formatrice d'explorer divers aspects du développement, de la gestion de projet, et du travail en équipe. En participant activement à la refonte d'une page, j'ai pu concrétiser mes connaissances du développement web."],
            skills: ["JavaScript", "Preact.js", "UX Design"]
        },
        en: {
            title: "Quentoo",
            date: "End-of-Studies Internship | Quentoo | June - July 2022",
            descriptions: ["During my internship at Quentoo (Poland), I had the opportunity to explore various aspects of development, project management, and teamwork. Actively participating in the revamping of a page allowed me to apply my knowledge of web development in practice."],
            skills: ["JavaScript", "Preact.js", "UX Design"]
        },
    },
    {
        id: 3,
        fr: {
            title: "Repozen",
            date: "Expérience Bénévole | Projet REPOZEN | Février - Septembre 2022",
            descriptions: ["Durant ma formation professionnelle, j’ai consacré bénévolement mon temps libre à la création du projet REPOZEN, une application novatrice facilitant la mise en relation entre particuliers et professionnels de la santé mentale, mettant en avant les associations offrant des aides gratuites.", "Ma contribution principale a été le développement du backoffice de l'application en utilisant React. Ce projet d'envergure comprenait la conception et la mise en œuvre de fonctionnalités cruciales telles que la connexion, une messagerie intégrée, la gestion complète des posts (CRUD), un tableau de bord intuitif, la gestion des FAQs et des paramètres de compte utilisateur. Chacune de ces fonctionnalités était développée en React, s'appuyant sur une API Node.", "Cette expérience a été ma première immersion dans le monde professionnel, où j'ai acquis des compétences essentielles en gestion de projet, en design UX/UI, et en collaboration avec d'autres professionnels du web.", "Au-delà du développement technique, cette expérience a été une opportunité unique d'explorer différentes facettes du monde professionnel, renforçant ma passion pour le développement web et ma détermination à contribuer à des projets significatifs ayant un impact positif sur la société."],
            skills: ["React", "Redux", "Jest", "SCSS", "Figma", "Node.js", "UX Design", "Responsive Design"]
        },
        en: {
            title: "Repozen",
            date: "Volunteer Work | REPOZEN Project | February - September 2022",
            descriptions: ["During my studies, I dedicated my free time to the voluntary development of the REPOZEN project, an innovative app facilitating the connection between individuals and mental health professionals, highlighting associations offering free assistance.", "My primary contribution was the development of the application's back office using React. This ambitious project included the design and implementation of crucial features such as login, integrated messaging, comprehensive post management (CRUD), an intuitive dashboard, FAQs management, and user account settings. Each of these features was developed in React, relying on a Node API.", "This experience marked my first step into the professional world, where I acquired essential skills in project management, UX/UI design, and collaboration with other web professionals.", "Beyond the technical development, this experience was a unique opportunity to explore various aspects of the professional realm, strengthening my passion for web development and my determination to contribute to meaningful projects with a positive impact on society."],
            skills: ["React", "Redux", "Jest", "SCSS", "Figma", "Node.js", "UX Design", "Responsive Design"]
        },
    },
    {
        id: 4,
        fr: {
            title: "Alt Rh",
            date: "Formation Professionnelle |  ALT RH | Décembre 2021 - Juillet 2022",
            descriptions: ["Formation professionnelle de développeur d'application web et mobile, axés sur le développement front-end et comprenant une variété de technologies clé. Au cours de cette période, j'ai acquis des compétences solides dans plusieurs domaines du développement web, renforçant ainsi ma base de connaissances et élargissant ma palette de compétences."],
            skills: ["HTML/CSS", "JavaScript", "React", "Bootsrap", "Node.js", "PHP", "Symfony", "Gestion de projet", "UX/UI Design", "Wordpress"]
        },
        en: {
            title: "Alt Rh",
            date: "Professional Studies |  ALT RH | December 2021 - July 2022",
            descriptions: ["Professional studies as a web and mobile application developer, with a focus on front-end development and including a variety of key technologies. Throughout this period, I gained strong skills in multiple areas of web development, enhancing my knowledge base and broadening my skill set."],
            skills: ["HTML/CSS", "JavaScript", "React", "Bootsrap", "Node.js", "PHP", "Symfony", "Project management", "UX/UI Design", "Wordpress"]
        },
    },
    {
        id: 5,
        fr: {
            title: "OpenClassroom",
            date: "Découverte du développement Web | Openclassroom | Septembre 2020 - Décembre 2021",
            descriptions: ["J’ai découvert le développement web à travers une exploration passionnante, qui m’a conduit à suivre une formation sur Python, puis une formation de développeur web chez Openclassroom. Ces expériences m'ont permis de plonger dans l'univers du développement web, acquérant ainsi les compétences fondamentales nécessaires pour embrasser cette carrière.", "Au cours de cette période formatrice, j'ai eu l'opportunité de toucher à divers aspects du développement, explorant différentes branches pour comprendre les choix de spécialités qui se présentaient à moi. Cette exploration m'a permis de développer une compréhension holistique du domaine et de confirmer mon intérêt croissant pour le développement front-end, en mettant particulièrement l'accent sur l'UX et l'UI.", "Parallèlement, j'ai développé un intérêt marqué pour la gestion de projet, comprenant l'importance de la planification et de la coordination pour le succès d'un projet web. "],
            skills: ["Python", "HTML/CSS", "JavaScript", "PHP", "Figma", "UX/UI Design", "Accessibilité", "Gestion de projet"]
        },
        en: {
            title: "OpenClassroom",
            date: "Discovery of Web Development | Openclassroom | September 2020 - December 2021",
            descriptions: ["My journey into web development began with an exciting exploration that motivated me to enroll in a learning program for Python and front-end web development at Openclassroom. These experiences immersed me in the dynamic world of web development, where I learned the fundamental skills to pursue a career in this field.", "During this learning period, I explored various aspects of development, discovering the specialization choices available to me. This exploration gave me a holistic understanding of the field and confirmed my growing passion for front-end development, especially UX and UI.", "At the same time, I developed a keen interest in project management, realizing the importance of planning and coordination for the success of a web project."],
            skills: ["Python", "HTML/CSS", "JavaScript", "PHP", "Figma", "UX/UI Design", "Accessibility", "Project management"]
        },
    },
    {
        id: 6,
        fr: {
            title: "CY Cergy Paris Université - Faure",
            date: "Droit Français - DUT Droit anglais et américain | Assistante Juridique chez Faure | 2018 - 2020",
            descriptions: ["Avant de m'engager dans le domaine du développement web, mon parcours académique initial s'est caractérisé par l'obtention d'un DUT en droit anglais et américain, ainsi que par la validation de ma deuxième année de droit français. Travaillant simultanément en tant qu'assistante juridique, j'ai pris conscience d'un désir croissant de changement. Bien que mon expérience professionnelle m'ait offert une compréhension pratique du monde juridique, j'ai ressenti le fort besoin de me réorienter vers une carrière mieux alignée sur mes aspirations."],
            skills: []
        },
        en: {
            title: "CY Cergy Paris Université - Faure",
            date: "French Law - DUT in English and American Law | Legal Assistant at Faure | 2018 - 2020",
            descriptions: ["Before joining into the field of web development, my initial academic journey was marked by obtaining a DUT in English and American law, and completing my second year in French law. While working concurrently as a legal assistant, I became increasingly aware of a growing desire for change. Although my professional experience provided me with a practical understanding of the legal world, I strongly felt the need to redirect myself toward a career more closely aligned with my aspirations."],
            skills: []
        },
    },
]

export default experiences